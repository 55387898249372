var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.item,
      title: _vm.model && _vm.item.id ? "Atualizar tipo" : "Cadastrar tipo",
      icon: "mdi-cube-outline",
      show: _vm.dialog,
      maxWidth: "30%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.item = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "mt-1" },
              [
                _vm.message
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              color: "warning",
                              prominent: "",
                              border: "left"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.message) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Nome*",
                    md: "12",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.name
                  },
                  model: {
                    value: _vm.item.name,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "name", $$v)
                    },
                    expression: "item.name"
                  }
                }),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Icone",
                    md: "6",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.icon
                  },
                  model: {
                    value: _vm.item.icon,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "icon", $$v)
                    },
                    expression: "item.icon"
                  }
                }),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Cor",
                    md: "6",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.color
                  },
                  model: {
                    value: _vm.item.color,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "color", $$v)
                    },
                    expression: "item.color"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }